<template>
  <page-content>



    <div></div>

    <a-card class="data-card" >

      <a-space slot="title">
        选择月份：
        <a-month-picker placeholder="所有" @change="onMonthChange" />
      </a-space>

      <div class="data-content">

        <div class="item">
          <div class="title">累计奖励</div>
          <div class="value">{{overview.totalReward/100}}</div>
        </div>

        <div class="item">
          <div class="title">已发奖励</div>
          <div class="value">{{overview.paidReward/100}}</div>
        </div>

        <div class="item">
          <div class="title">扣除奖励</div>
          <div class="value">{{overview.deductionReward/100}}</div>
        </div>

        <div class="item">
          <div class="title">待发奖励</div>
          <div class="value">{{overview.currentReward/100}}</div>
        </div>

        <div class="item">
          <div class="title">提交次数</div>
          <div class="value">{{overview.rewardCount}}</div>
        </div>

      </div>

    </a-card>

    <a-space align="start">
      <a-card title="团队">
        <common-table
            ref="table"
            path="web/machine/team"
            :query-params="queryParams"
            :columns="columns"
        >
          <template slot="search">
<!--            <a-button ghost type="primary" @click="add">新增</a-button>-->
          </template>

          <template slot="operation" slot-scope="{record}">
            <a-button size="small" @click="clickSub(record)">查看团队成员</a-button>
          </template>

        </common-table>
      </a-card>


      <a-card :title=" selectedTeam.name + '-团队成员'" v-if="selectedTeam.id">
        <common-table
            ref="childrenTable"
            path="web/machine/team-member/tree"
            :pageable="false"
            :columns="memberColumns"
            :query-params="{teamId:this.selectedTeam.id,...queryParams}"
        >

          <template slot="search">
<!--            <a-button ghost type="primary" @click="addMember">新增</a-button>-->
          </template>

          <template slot="operation" slot-scope="{record}">
            <action-edit @click="editMember(record)"></action-edit>
            <action-delete @click="removeMember(record)"></action-delete>
          </template>

        </common-table>
      </a-card>

    </a-space>

  </page-content>
</template>

<script>


import moment from 'moment'
export default {
  components: {

  },
  data() {
    return {
      selectedTeam: {},
      queryParams:{
        timeFrom:0,
        timeTo:0
      },
      overview:{

      }
    }
  },

  computed: {
    columns() {
      return [
        {
          title: '名称',
          dataIndex: 'name'
        },
        {
          title: '累计奖励',
          dataIndex: 'totalReward',
          customRender(text, record) {
            return text ? (text/100 + "元") : "0"
          }
        },
        {
          title: '已发奖励',
          dataIndex: 'paidReward',
          customRender(text, record) {
            return text ? (text/100 + "元") : "0"
          }
        },
        {
          title: '扣除奖励',
          dataIndex: 'deductionReward',
          customRender(text, record) {
            return text ? (text/100 + "元") : "0"
          }
        },
        {
          title: '待发奖励',
          dataIndex: 'currentReward',
          customRender(text, record) {
            return text ? (text/100 + "元") : "0"
          }
        },
        {
          title: '提交次数',
          dataIndex: 'rewardCount',
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'}
        }
      ]
    },
    memberColumns() {
      return [
        {
          title: '姓名',
          dataIndex: 'name'
        },
        {
          title: '手机号',
          dataIndex: 'phone'
        },
        {
          title: '累计奖励',
          dataIndex: 'totalReward',
          customRender(text, record) {
            return text ? (text/100 + "元") : "0"
          }
        },
        {
          title: '已发奖励',
          dataIndex: 'paidReward',
          customRender(text, record) {
            return text ? (text/100 + "元") : "0"
          }
        },
        {
          title: '扣除奖励',
          dataIndex: 'deductionReward',
          customRender(text, record) {
            return text ? (text/100 + "元") : "0"
          }
        },
        {
          title: '待发奖励',
          dataIndex: 'currentReward',
          customRender(text, record) {
            return text ? (text/100 + "元") : "0"
          }
        },
        {
          title: '提交次数',
          dataIndex: 'rewardCount',
        },
        // {
        //   title: '操作',
        //   dataIndex: 'operation',
        //   scopedSlots: {customRender: 'operation'}
        // }
      ]
    },
  },
  created(){
    this.getOverview()
  },
  methods: {
    getOverview(){
      this.$get('web/machine/reward/getAllReward',this.queryParams).then(suc=>{
        this.overview = suc.data
      })
    },
    add() {
      this.$refs.teamEdit.show()
    },
    addMember() {
      this.$refs.teamMemberEdit.show({teamId: this.selectedTeam.id})
    },
    edit(record) {
      this.$refs.teamEdit.show(record)
    },
    editMember(record) {
      this.$refs.teamMemberEdit.show(record)
    },
    remove(record) {
      let that = this
      this.$confirm({
        title: '是否删除该记录?',
        content: '该操作不可撤销',
        centered: true,
        onOk() {
          that.$delete('web/machine/team/' + record.id).then(() => {
            that.$message.success('删除成功')
            that.getList()
            if (that.selectedTeam.id == record.id) {
              that.selectedTeam = {}
            }
          })
        }
      })
    },
    removeMember(record) {
      let that = this
      this.$confirm({
        title: '是否删除该记录?',
        content: '该操作不可撤销',
        centered: true,
        onOk() {
          that.$delete('web/machine/team-member/' + record.id).then(() => {
            that.$message.success('删除成功')
            that.getList()
            if (that.selectedTeam.id == record.id) {
              that.selectedTeam = {}
            }
          })
        }
      })
    },
    getList() {
      this.$refs.table.getData()
      if(this.selectedTeam.id){
        this.$refs.childrenTable.getData()
      }
    },
    clickSub(record) {
      this.selectedTeam = record
      this.$refs.childrenTable.getData()
    },

    onMonthChange(dates,dateString){
      //选择月份，dateString格式 2023-05
      console.log(dateString)
      if(dateString){
        let arr = dateString.split("-")
        let year = parseInt(arr[0])
        let month = parseInt(arr[1])
        let date = new Date(dateString+"-01")
        let start = date.getTime() - 8*3600*1000
        date.setMonth(month)
        date.setDate(0)
        let end = date.getTime() + 16*3600*1000
        this.queryParams.timeFrom = parseInt(start/1000)
        this.queryParams.timeTo = parseInt(end/1000)
      }else{
        this.queryParams.timeFrom = 0
        this.queryParams.timeTo = 0
      }

      setTimeout(()=>{
        this.getList()
        this.getOverview()
      },300)

    }
  }
}
</script>

<style lang="less" scoped>
.data-card {

  .data-content {
    display: flex;

    .item{
      text-align: center;
      width: 180px;
      margin: 0 10px;
      padding: 5px 0;
      border-radius: 5px;
    }

    .title{
      color: #666666;
      font-size: 16px;
    }

    .value{
      color: #333333;
      font-weight: 600;
      font-size: 24px;
      margin-top: 10px;
    }
  }

  .clickable{
    cursor: pointer;
  }

  .clickable:hover{
    background: #F8F8F8;
  }
}
</style>
